import apiService from '@/common/api.service'

export function getClinicData (id) {
  return apiService({
    url: '/api/clinic',
    method: 'get',
    params: { id }
  })
}

export function updateClinicInitData (data) {
  return apiService({
    url: '/api/clinic/initialization',
    method: 'patch',
    data
  })
}

export function getDashboardData () {
  return apiService({
    url: '/api/clinic/dashboard',
    method: 'get'
  })
}
export function getPromoter () {
  return apiService({
    url: '/api/clinic/promoter',
    method: 'get'
  })
}
