<template>
  <div class="d-flex align-items-center mb-4">
    <img :src="imgSrc" class="mr-4" width="48" height="48">

    <span>
      {{ cycleTypeName }}
    </span>

    <template v-if="type === 'testing'">
      <b-button
        v-if="stage === 1"
        variant="secondary"
        class="text-primary flex-shrink-0 ml-auto"
        disabled
      >
        <!-- <font-awesome-icon :icon="['fas', 'spinner']" fixed-width spin /> -->
        Incomplete
      </b-button>
      <div v-if="stage === 2" class="flex-shrink-0 ml-auto">
        <b-button
          variant="secondary"
          class="text-primary mr-2"
          @click="$emit('action')"
        >
          <font-awesome-icon :icon="['fas', 'upload']" fixed-width />
          Upload
        </b-button>
        <b-button
          variant="secondary"
          class="text-primary flex-shrink-0 ml-auto"
          @click="$emit('complete')"
        >
          <font-awesome-icon :icon="['fas', 'check-circle']" fixed-width />
          complete
        </b-button>
      </div>
    </template>
    <template v-if="(type === 'maintenance' || type === 'cleaning') && stage === 1">
      <b-button
        variant="secondary"
        class="text-primary flex-shrink-0 ml-auto"
        @click="$emit('action')"
      >
        <font-awesome-icon :icon="['fas', 'check-circle']" fixed-width />
        complete
      </b-button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CycleTypeItem',
  props: {
    type: {
      type: String,
      required: true
    },
    cycleTypeName: {
      type: String,
      default: ''
    },
    cycleTypeValue: {
      type: Number,
      default: 0
    },
    stage: {
      type: Number,
      required: true
    }
  },
  computed: {
    imgSrc () {
      switch (this.cycleTypeValue) {
        case 0:
          return '/images/cycle/program.svg'
        case 10:
          return '/images/cycle/testing/icon-others-reminder-normal-Leakage_Test.svg'
        case 11:
          return '/images/cycle/testing/icon-others-reminder-normal-Helix_Test.svg'
        case 12:
          return '/images/cycle/testing/icon-others-reminder-normal-B_D_Test.svg'
        case 13:
          return '/images/cycle/testing/icon-others-reminder-Biological_Indicator_Test.svg'
        case 14:
          return '/images/cycle/testing/icon-others-reminder-Chemical_Indicator_Test.svg'
        case 100:
          return '/images/cycle/maintenance/icon-others-reminder-normal-Replace_Exhaust_Filter.svg'
        case 101:
          return '/images/cycle/maintenance/icon-others-reminder-normal-Replace_Air_Filter.svg'
        case 102:
          return '/images/cycle/maintenance/icon-others-reminder-normal-Replace_Door_Gasket.svg'
        case 103:
          return '/images/cycle/maintenance/icon-others-reminder-normal-Replace_Clean_Water.svg'
        case 104:
          return '/images/cycle/maintenance/icon-others-reminder-normal-Check_Safety_Valve.svg'
        case 105:
          return '/images/cycle/maintenance/icon-others-reminder-normal-Check_Power_Cord.svg'
        case 106:
          return '/images/cycle/maintenance/icon-others-reminder-normal-Check_Water_Quality.svg'
        case 107:
          return '/images/cycle/maintenance/icon-others-reminder-normal-Temperature_Calibration.svg'
        case 108:
          return '/images/cycle/maintenance/icon-others-reminder-normal-Replace_Steam_Trap.svg'
        case 109:
          return '/images/cycle/maintenance/icon-others-reminder-normal-Annual_Maintenance.svg'
        case 200:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Wipe_Shell.svg'
        case 201:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Wipe_Chamber.svg'
        case 202:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Wipe_Door_Gasket.svg'
        case 203:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Clean_Sterilization_Accessories.svg'
        case 204:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Clean_Watertank_Filter.svg'
        case 205:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Clean_CT.svg'
        case 206:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Clean_Chamber.svg'
        case 207:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Clean_Drain_Filter.svg'
        case 208:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Clean_FT.svg'
        case 209:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Clean_Chamber_Water_Sensor.svg'
        case 210:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Clean_Waterlevel_Sensor.svg'
        case 211:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Clean_Chamber.svg'
        case 212:
          return '/images/cycle/cleaning/icon-others-reminder-normal-Clean_Cooling_Fan.svg'
        case 999:
          return '/images/cycle/other.svg'
        default:
          return ''
      }
    }
  }
}
</script>
