<template>
  <div>
    <div
      v-if="autoclavePageList.length > 0"
      class="custom-pagination d-flex justify-content-start mb-6"
      :class="{
        'hasnext': currentPage !== autoclavePageList.length,
        'hasprev': currentPage !== 1
      }"
    >
      <b-pagination
        v-model="currentPage"
        pills
        hide-goto-end-buttons
        hide-ellipsis
        :total-rows="autoclavePageList.length"
        :per-page="1"
        :limit="5"
      >
        <template #page>
          <span />
        </template>
        <template #prev-text>
          <font-awesome-icon :icon="['fas', 'caret-left']" fixed-width />
        </template>
        <template #next-text>
          <font-awesome-icon :icon="['fas', 'caret-right']" fixed-width />
        </template>
      </b-pagination>
      <div class="d-flex align-items-center ml-5">
        <row-count :total-rows="autoclavePageList.length" :row-pre-page="1" :current-page="currentPage" />
      </div>
    </div>

    <b-row>
      <b-col v-for="autoclave in autoclavePageList[currentPage - 1]" :key="autoclave.id" cols="4">
        <div class="areawrap">
          <h2 class="d-flex justify-content-between">
            <span>{{ autoclave.name || '-' }}</span>
            <b-button
              variant="secondary"
              :class="autoclave.vip === 0 ? 'text-gray-500' : 'text-warning'"
              @click="updateAutoclaveVipStatus(autoclave)"
            >
              <font-awesome-icon :icon="['fas', 'bookmark']" fixed-width />
              VIP
            </b-button>
          </h2>
          <p class="mb-6">
            {{ autoclave.serial_number }}
          </p>

          <template v-if="autoclave.worker">
            <template v-if="autoclave.worker.status === 2">
              <h3 class="h2 d-flex align-items-center mb-0">
                <div class="font-size-lg font-weight-bold">
                  Error {{ autoclave.worker.error_code }}
                </div>
                <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="text-danger ml-auto" fixed-width />
              </h3>
              <p class="h2 font-weight-bold mb-6 d-flex justify-content-between">
                <span>{{ autoclave.worker.content }}</span>
                <b-button
                  variant="primary"
                  class="btn-width"
                  @click="onContactClick(autoclave.id, autoclave.worker.error_code, autoclave.worker.content)"
                >
                  Contact
                </b-button>
              </p>
            </template>
            <template v-else-if="(autoclave.worker.duration - (currentDate - new Date(autoclave.worker.start_time)) / 1000) > 0">
              <h3 class="h2 d-flex justify-content-between align-items-end">
                <div>
                  <span>{{ getHumanizedTime(autoclave.worker.start_time, autoclave.worker.duration) }}</span><br>
                  <span v-if="autoclave.worker.process_name" class="h6 font-weight-normal">{{ autoclave.worker.process_name }}</span>
                </div>
                <span class="h6 font-weight-normal mb-0">{{ autoclave.worker.content }}</span>
              </h3>
              <div class="m-progress">
                <b-progress
                  class="rounded-pill"
                  height="1rem"
                  :max="autoclave.worker.duration"
                  :value="autoclave.worker.duration - (currentDate - new Date(autoclave.worker.start_time)) / 1000"
                />
              </div>
            </template>
            <template v-else>
              <h3 class="h2 d-flex justify-content-between align-items-end">
                <span class="font-weight-bold">Complete</span>
                <span class="h6 font-weight-normal mb-0">{{ autoclave.worker.content }}</span>
              </h3>
              <div class="m-progress">
                <b-progress
                  variant="success"
                  class="rounded-pill"
                  height="1rem"
                  :max="100"
                  :value="100"
                />
              </div>
            </template>
          </template>

          <template v-if="autoclave.testingList.length > 0">
            <hr>
            <h4 class="h2 font-weight-bold mb-4">
              Functional Testings
            </h4>
            <cycle-type-item
              v-for="testing in autoclave.testingList"
              :key="testing.cycle_id"
              type="testing"
              :cycle-type-name="testing.cycleTypeName"
              :cycle-type-value="testing.cycleTypeValue"
              :stage="testing.stage"
              @action="onUploadClick(testing.cycle_id)"
              @complete="showCompleteCycleModal(autoclave.id, testing.type)"
            />
          </template>

          <template v-if="autoclave.maintenanceList.length > 0">
            <hr>
            <h4 class="h2 font-weight-bold mb-4">
              Maintenance
            </h4>
            <cycle-type-item
              v-for="maintenance in autoclave.maintenanceList"
              :key="maintenance.cycle_id"
              type="maintenance"
              :cycle-type-name="maintenance.cycleTypeName"
              :cycle-type-value="maintenance.cycleTypeValue"
              :stage="maintenance.stage"
              @action="showCompleteCycleModal(autoclave.id, maintenance.type)"
            />
          </template>

          <template v-if="autoclave.cleaningList.length > 0">
            <hr>
            <h4 class="h2 font-weight-bold mb-4">
              Cleaning
            </h4>
            <cycle-type-item
              v-for="cleaning in autoclave.cleaningList"
              :key="cleaning.cycle_id"
              type="cleaning"
              :cycle-type-name="cleaning.cycleTypeName"
              :cycle-type-value="cleaning.cycleTypeValue"
              :stage="cleaning.stage"
              @action="showCompleteCycleModal(autoclave.id, cleaning.type)"
            />
          </template>
        </div>
      </b-col>
    </b-row>

    <indicator-modal ref="indicatorModal" v-model="indicator" :cycle-id="currentCycleId" @input="getDashboardData" />
    <complete-cycle-modal ref="completeCycleModal" @update="getDashboardData" />
    <report-error-modal ref="reportErrorModal" />
  </div>
</template>

<script>
import IndicatorModal from '@/components/product/indicatorModal'
import CycleTypeItem from '@/components/dashboard/cycleTypeItem'
import CompleteCycleModal from '@/components/dashboard/completeCycleModal'
import cycleTypeList from '@/common/cycleTypeList'
import { getDashboardData } from '@/api/clinic'
import { updateAutoclaveVipStatus } from '@/api/autoclave'
import reportErrorModal from '@/components/dashboard/reportErrorModal'
import RowCount from '@/components/common/rowCount'

export default {
  name: 'Dashboard',
  components: {
    IndicatorModal,
    CycleTypeItem,
    CompleteCycleModal,
    reportErrorModal,
    RowCount
  },
  data () {
    return {
      timer: null,
      datatimer: null,
      currentDate: +new Date(),

      currentPage: 1,
      autoclaveList: [],

      currentCycleId: 0,
      indicator: {
        type: null,
        indicator_uri: null,
        indicator_result: 0,
        indicator_uploaded_by: '',
        indicator_uploaded_time: ''
      }
    }
  },
  computed: {
    autoclavePageList () {
      const dataList = JSON.parse(JSON.stringify(this.autoclaveList))
      const pageCount = 3
      return dataList.reduce((list, item) => {
        item.maintenances.forEach(maintenance => {
          const cycleType = cycleTypeList.map(p => p.options).flat().find(cycle => cycle.value === maintenance.type)
          maintenance.cycleTypeName = cycleType?.text
          maintenance.cycleTypeValue = cycleType?.value
        })
        item.testingList = item.maintenances.filter(maintenance => maintenance.category === 0)
        item.maintenanceList = item.maintenances.filter(maintenance => maintenance.category === 1)
        item.cleaningList = item.maintenances.filter(maintenance => maintenance.category === 2)

        const currentPageList = list[list.length - 1]
        if (currentPageList.length >= pageCount) list.push([item])
        else currentPageList.push(item)
        return list
      }, [[]])
    }
  },
  created () {
    this.timer = setInterval(this.setCurrentDate, 1000)

    this.getDashboardData()
    this.datatimer = setInterval(() => {
      setTimeout(this.getDashboardData, 0)
    }, 5000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
    clearInterval(this.datatimer)
  },
  methods: {
    setCurrentDate () {
      this.currentDate = +new Date()
    },
    getHumanizedTime (startTime, duration) {
      const start = this.$dayjs(startTime)
      const end = this.$dayjs(this.currentDate)
      const value = duration - end.diff(start, 'second')

      let second = Math.floor(value % 60).toString()
      if (second.length === 1) second = '0' + second

      let min = Math.floor((value / 60) % 60).toString()
      if (min.length === 1) min = '0' + min

      let hour = Math.floor((value / 60 / 60) % 60).toString()
      if (hour.length === 1) hour = '0' + hour

      return `${hour}:${min}:${second}`
    },
    getDashboardData () {
      getDashboardData().then(res => {
        this.autoclaveList = res.data.data.autoclaves
      })
    },
    updateAutoclaveVipStatus ({ id, vip }) {
      const data = {
        id,
        vip: vip === 0 ? 1 : 0
      }
      updateAutoclaveVipStatus(data).then(() => {
        this.getDashboardData()
      })
    },
    showCompleteCycleModal (autoclaveId, cycleType) {
      const data = {
        autoclave_id: autoclaveId,
        type: cycleType
      }
      this.$refs.completeCycleModal.show(data)
    },
    onUploadClick (id) {
      this.currentCycleId = id
      this.$refs.indicatorModal.show()
    },
    onContactClick (id, code, content) {
      this.$refs.reportErrorModal.show(id, `${code} ${content}`)
    }
  }
}
</script>
