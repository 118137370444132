<template>
  <b-modal
    id="report-error-modal"
    v-model="formShow"
    title="Contact service provider"
    cancel-title="Cancel"
    ok-title="Send"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <b-row>
        <b-col cols="6" class="mb-5">
          <span class="font-size-sm text-primary"> Service provider's phone</span>
        </b-col>
        <b-col cols="6" class="mb-5">
          <span>
            {{ vendorInfo.contact_telephone }}
          </span>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Title"
            class="mb-4"
            :state="$v.form.$anyDirty ? !$v.form.title.$anyError : null"
          >
            <b-form-input v-model="form.title" />
            <b-form-invalid-feedback
              :state="$v.form.$anyDirty ? $v.form.title.required : null"
            >
              Title is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Content"
            class="mb-4"
            :state="$v.form.$anyDirty ? !$v.form.content.$anyError : null"
          >
            <b-form-textarea v-model="form.content" />
            <b-form-invalid-feedback
              :state="$v.form.$anyDirty ? $v.form.content.required : null"
            >
              Content is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <file-upload v-for="index in 5" :key="index" :index="index-1" @file-picked="onFilePicked" @cancel="onFileCancel" />
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { createService, uploadFile } from '@/api/service'
import { getPromoter } from '@/api/clinic'
import FileUpload from '@/components/dashboard/fileUpload'

export default {
  name: 'ReportErrorModal',
  components: {
    FileUpload
  },
  data () {
    return {
      vendorInfo: {
        contact_telephone: '0284839209'
      },
      form: {
        autoclave_id: 0,
        title: '',
        content: '',
        type: 0,
        autoclave_message: '',
        files: []
      },
      formShow: false
    }
  },
  validations () {
    return {
      form: {
        title: { required },
        content: { required }
      }
    }
  },
  created () {
    getPromoter().then(res => {
      this.vendorInfo.contact_telephone = res.data.data.partner.corporate_telphone
    })
  },
  methods: {
    show (id, msg) {
      this.$bvModal.show('report-error-modal')
      this.form = {
        autoclave_id: id,
        title: '',
        content: '',
        type: 0,
        autoclave_message: msg,
        files: [null, null, null, null, null]
      }
      this.formShow = true
      this.$v.form.$reset()
    },
    hide () {
      this.$bvModal.hide('report-error-modal')
      this.formShow = false
    },
    async submit (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      const attachments = []
      // upload files
      for (const f of this.form.files.filter(a => a)) {
        await uploadFile(f).then(res => {
          attachments.push(res.data.data.attachment_id)
        })
      }
      const data = {
        autoclave_id: this.form.autoclave_id,
        title: this.form.title,
        content: this.form.content,
        type: 0,
        attachments: attachments,
        autoclave_message: this.form.autoclave_message
      }
      createService(data).then(() => {
        this.hide()
      })
    },
    async onFilePicked (index, file) {
      this.form.files[index] = file
    },
    onFileCancel (index) {
      this.form.files[index] = null
    }
  }
}
</script>
