<template>
  <b-modal
    id="complete-cycle-modal"
    cancel-title="CANCEL"
    ok-title="CONFIRM"
    hide-header-close
    centered
    @ok="createCycle"
  >
    <div class="px-4">
      Confirm completed {{ text }}?
    </div>
  </b-modal>
</template>

<script>
import { createCycle, completeCycle } from '@/api/cycle'
import cycleTypeList from '@/common/cycleTypeList'

export default {
  name: 'InfoModal',
  data () {
    return {
      data: {},
      text: ''
    }
  },
  methods: {
    show (data) {
      this.text = cycleTypeList.map(p => p.options).flat().find(cycle => cycle.value === data.type).text
      this.$bvModal.show('complete-cycle-modal')
      this.data = data
    },
    hide () {
      this.$bvModal.hide('complete-cycle-modal')
    },
    async createCycle () {
      if (this.data.type >= 10 && this.data.type <= 99) {
        await completeCycle(this.data).catch(err => {
          console.log(err)
        })
      } else {
        await createCycle(this.data).catch(err => {
          console.log(err)
        })
      }
      this.$emit('update')
    }
  }
}
</script>
